<template>
    <div class="page_wrapper form">
        <div class="form_inner">
            <div class="page_labels mb-7">
                <div class="header">
                    <h1 class="title text-center mb-2">
                        Восстановление пароля
                    </h1>
                    <p class="description text-center">
                        Придумайте надежный пароль для входа в ваш аккаунт
                    </p>
                </div>
            </div>
    
            <a-form-model
                ref="resetPasswordForm"
                :model="form"
                layout="vertical"
                autocomplete="off"
                :rules="rules">
                <a-form-model-item
                    ref="password"
                    class="mb-2"
                    label="Введите новый пароль"
                    prop="password">  
                    <a-input-password
                        placeholder="Придумайте надежный пароль"
                        v-model="form.password"
                        size="large"/>
                </a-form-model-item>
                <a-form-model-item
                    ref="password2"
                    class="mb-2"
                    label="Повторите пароль"
                    prop="password2">
                    <a-input-password
                        placeholder="Повторите пароль"
                        v-model="form.password2"
                        size="large"/>
                </a-form-model-item>
                <div>
                    <a-button
                        type="primary"
                        :loading="loading"
                        class="px-10 form_buttom"
                        block
                        ghost
                        size="large"
                        @click="formSubmit()">
                        Восстановить
                    </a-button>
                </div>
            </a-form-model>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    computed: {
        ...mapState({
            usersAuth: state => state.user.usersAuth,
            loginProgress: state => state.loginProgress
        }),
        loginEmail() {
            return this.$refs['loginEmail']
        },
        loginPassword() {
            return this.$refs['loginPassword']
        },
        id(){
            return this.$route.params.id
        }
    },
    data() {
        let validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('field_required')));
            } else if (value !== this.form.password) {
                callback(new Error("Пароли не совпадают"));
            } else {
                callback();
            }
        };
        return {
            selected: [],
            loading: false,
            form: {
                password: "",
                password2: ""
            },
            rules: {
                
                password: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { min: 8, message: this.$t('required_sym', { sym: 8 }), trigger: 'change' }
                ],
                password2: [
                    { validator: validatePass, trigger: 'change' },
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { min: 8, message: this.$t('required_sym', { sym: 8 }), trigger: 'change' }
                ],

            }
        }
    },
    created(){
        this.getStatus()
    },
    metaInfo() {
        return {
            title: 'Восстановление пароля | Gos24.КОННЕКТ'
        }
    },
    methods: {
       
        async getStatus(){
            if(this.id){ 
                const {data} = await this.$http(`users/reset/password/${this.id}`)
                if(data.status !== 'ok') this.pushRoute()
            }else {
                this.pushRoute()
            }
        },
        pushRoute(){
            this.$message.error('Недействительная ссылка!')
            this.$router.push({name: 'login'})
        },
        selectUser(user) {
            this.selected = [user.email]
            this.form.email = user.email
            this.$nextTick(() => {
                this.loginPassword.focus()
            })
        },
        formSubmit() {
            this.$refs.resetPasswordForm.validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        const {data} = await this.$http.put(`users/reset/password/${this.id}/`, this.form)
                        this.$message.success('Вы успешно сменили пароль')
                        this.$router.push({name: 'login'})
                    } catch(e) {
                        if(e.non_field_errors){
                            this.$message.error(String(e.non_field_errors), 4)
                        }
                        console.log(e)
                    
                    } finally {
                        this.loading = false
                    }
                } else {
                    this.$message.warning(this.$t('fill_required_fields'))
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.input_large{
    &::v-deep{
        .ant-input{ 
            height: 65px;
        } 
    }
}
.pass_check{
    margin: initial;
    max-width: 100%;
    &::v-deep{
        .Password__strength-meter{
            margin: 10px auto 10px;
        }
    }
}
.password_text{
    font-size: 13px;
}

$breakpoint-1: 1200px;
$breakpoint-2: 680px;
.header {
    margin-bottom: 30px;
    color: #0C0C0E;
}
.title {
    color: #0C0C0E;
    font-size: 2rem;
}
.description {
    font-size: 1rem;
}
.form_inner {
    width: 100%;
    max-width: 470px;
    margin: 0 auto;
}

</style>